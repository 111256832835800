import {Col, Figure, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function DropArea() {

    return (
        <Col className="border border-secondary border-1 rounded h-100">
            <Row className="align-items-center h-100">
                <Figure className="text-center p-2 ">
                    <blockquote className="blockquote text-black-50">
                        <p><FontAwesomeIcon size="2xl" icon="file-export" /></p>
                        <span>Вы можете перетащить файлы в эту область</span>
                    </blockquote>
                </Figure>
            </Row>
        </Col>
    )
}

export default DropArea;

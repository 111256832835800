import {useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";

const HelpModal = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Button variant="link" onClick={handleShow}>
                Инструкция
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="xl"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Инструкция по заполнению</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tbody>
                            <tr>
                                <td colspan="5" className="text-center fw-medium">Части нейминга</td>
                            </tr>
                            <tr className="fw-medium">
                                <td>Локаль</td>
                                <td>Название приложения</td>
                                <td>Название ролика</td>
                                <td>Вариация ролика</td>
                                <td>Разрешение</td>
                            </tr>
                            <tr className="fw-medium">
                                <td>RU</td>
                                <td>10ME</td>
                                <td>Neverlearn</td>
                                <td>Animoji</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td colspan="5" className="text-center fst-italic">Локаль и разрешение всегда отделяем от названия символом "_". Элементы названия ролика разделяем символом "-".</td>
                            </tr>
                            <tr className="fst-italic">
                                <td>Локаль всегда обозначается только 2 символами. Список обозначений справа.</td>
                                <td>Название аппок стандартизировано и не должно меняться </td>
                                <td>В название ролика можно использовать любые символы, за исключением разделителей.</td>
                                <td>В качестве вариаций можно указывать как версии ролика (v1, v2), так и дополнительную информацию о креативам (Man, Freelancer, Animoji).</td>
                                <td>Для обозначения разрешения давайте использовать просто 2/3 цифры.</td>
                            </tr>
                            <tr>
                                <td colspan="5" className="text-center fw-medium">Пример названия</td>
                            </tr>
                            <tr>
                                <td colspan="5" className="text-center fw-medium">RU_10ME-NeverLearn-Animoji_11</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )};

export default HelpModal;
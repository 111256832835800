import {Container, Nav, Navbar} from "react-bootstrap";

function Navigation() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                    <img src="https://engbright.com/app/VAADIN/themes/hover/branding/app-icon-login.png" alt="Bright"/>
                    <span className="text-start ms-3 text-dark-emphasis">Bright Mobile Apps</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Navbar.Text>Filename Validator</Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    FormLabel,
    FormText,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faFileExport, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useRef, useState} from "react";
import DropArea from "../../components/validator/DropArea";
import FileList from "../../components/validator/FileList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FileDrop} from "react-file-drop";
import Navigation from "../../components/common/Navigation";
import LanguagesModal from "../../components/validator/LanguagesModal";
import DropboxChooser from "../../components/dropbox/DropboxChooser";
import CreatableSelect from "react-select/creatable";
import HelpModal from "../../components/validator/HelpModal";


library.add(faFileExport, faTrash);

function FilenameValidator() {
    const inputDirectoryRef = useRef(null);
    const inputRef = useRef(null);
    const selectResolutionInputRef = useRef();

    const [fileList, setFileList] = useState([]);
    const [project, setProject] = useState("Bright");
    const [videoFileName, setVideoFileName] = useState("");
    const [variation, setVariation] = useState("");
    const [resolutions, setResolutions] = useState([]);

    const [projects, setProjects] = useState(
        [
            {'value': 'Bright', 'label': 'Bright English' },
            {'value': 'BrightSP', 'label': 'Bright Spanish'},
            {'value': 'BrightAR', 'label': 'Bright Arabic'},
            {'value': '10ME', 'label': '10 Minute English'},
            {'value': '10MS', 'label': '10 Minute Spanish'},
            {'value': '10MF', 'label': '10 Minute French' },
            {'value': '10MJ', 'label': '10 Minute Japanese' },
        ])

    const allResolutions = [
        {'value': '11', 'label': '11'},
        {'value': '45', 'label': '45'},
        {'value': '169', 'label': '169'},
        {'value': '916', 'label': '916'},
    ];

    const [defaultResolutions, setDefaultResolutions] = useState(allResolutions);

    const languages = [
        ["EN", "Английский"],
        ["AR", "Арабский"],
        ["VN", "Вьетнамский"],
        ["NL", "Голландский"],
        ["GR", "Греческий"],
        ["HE", "Иврит"],
        ["IN", "Индийский"],
        ["ID", "Индонезийский"],
        ["SP", "Испанский (Евр.)"],
        ["LA", "Испанский (Лат.)"],
        ["IT", "Итальянский"],
        ["ZH", "Китайский (трад.)"],
        ["CN", "Китайский (упр.)"],
        ["KR", "Корейский"],
        ["MY", "Малайский"],
        ["DE", "Немецкий"],
        ["NO", "Норвежский"],
        ["PT", "Португальский"],
        ["RU", "Русский"],
        ["TH", "Тайский"],
        ["TR", "Турецкий"],
        ["UA", "Украинский"],
        ["FI", "Финский"],
        ["FR", "Французский"],
        ["CZ", "Чешский"],
        ["SE", "Шведский"],
        ["JP", "Японский"],
        ["PL", "Польский"]]

    const handleDirectoryClick = () => {
        inputDirectoryRef.current.click();
    };
    const handleClick = () => {
        inputRef.current.click();
    };

    const handleResolutionChange = (event) => {
        setResolutions(event.map((r) => {
            return r.value
        }));
    };

    const handleFileChange = event => {
        const fileObjs = [...event.target.files];
        updateFilenamesState(fileObjs);
        event.target.value = null;
    };
    const handleFileDrop = (files, event) => {
        const fileObjs = [...files];
        updateFilenamesState(fileObjs);
        event.target.value = null;
    };

    const clearForm = (event) => {
        setFileList([]);
        updateProjectsState("Bright");
        setVideoFileName("");
        setResolutions([]);
        setDefaultResolutions(allResolutions);
        selectResolutionInputRef.current.clearValue();
        setVariation("");
    };

    const updateFilenamesState = files => {
        setFileList(files.map((fileObj) => {
                return {
                    'isValid': undefined,
                    'name': fileObj.name,
                    'webkitRelativePath': fileObj.webkitRelativePath,
                    'directory': fileObj.webkitRelativePath ? fileObj.webkitRelativePath.replace(fileObj.name, '') : ''
                }
            }
        ))
    };

    const updateProjectsState = (proj) => {
        setProject(proj);
    };

    const validateFilenames = (event) => {
        setFileList(fileList.map((f) => {
            const validated = validateFilename(f.name);
            return {...f, isValid: validated[0], validationErrors: validated[1]}
        }));
    };

    const validateFilename = (filename) => {
        filename = filename.toLowerCase();
        filename = filename.split('.').slice(0, -1).join('.');
        var lowerProject = project.toLowerCase()
        const splitFilename = filename.split('_');
        var errors = [];
        var nameVariation = '';
        var variations = variation.split(',').map((e) => e.trim().toLowerCase());

        if (splitFilename.length === 3) {
            var locale = splitFilename[0];
            var name = splitFilename[1];
            var fileResolution = splitFilename[2];
        } else {
            errors.push('Имя файла не удовлетворяет шаблону {Локаль}_{Проект}-{Имя ролика}-{Вариация}_{Разрешение} = LA_10ME-GoldReserve-Man_11');
            return [false, errors];
        }

        const splitName = name.split('-');

        if (splitName.length > 2) {
            nameVariation = splitName.pop();
            nameVariation = nameVariation.trim();
            name = splitName.join('-');
        }
        if (nameVariation!=='') {
            if (!variation) {
                errors.push('В названии файла присутствует недопустимая вариация');
            } else if (!variations.includes(nameVariation)) {
                errors.push('Вариация не совпадает');
            }
        } else {
            if (variation) {
                console.log('variations='+variations+';nameVariation='+nameVariation);
                errors.push('Вариация не совпадает');
            }
        }

        if (variation){
            for (var v in variations) {
                if (name.includes(variations[v])) {
                    errors.push('Имя файла включает вариацию ('+variations[v]+')');
                }
            }
        }

        if (!languages.map((p) => p[0].toLowerCase()).includes(locale)) {
            errors.push('Недопустимый код языка (' + locale + ')');
        }

        if (!name.startsWith(lowerProject + '-')) {
            errors.push('В названии указан другой проект');
        }

        const nameRegex = project + '|' + lowerProject + '-(?![-_]).*';
        if (!name.match(new RegExp(nameRegex))) {
            errors.push('Имя файла содержит недопустимые символы');
        }

        const onlyName = name.replace(lowerProject + '-', '');
        if (onlyName !== videoFileName.toLowerCase()) {
            errors.push('Имя файла не соответствует указанному имени ролика')
        }

        if (!resolutions.includes(fileResolution)) {
            errors.push('Недопустимое разрешение')

        }
        if (errors.length > 0) {
            return [false, errors]
        }
        return [true, []];

    };

    return (
        <Container>
            <Navigation/>
            <Container className="bg-body-tertiary mt-5 pb-3">
                <Row>
                    <Col className="col-2 m-2">
                        <Row className="p-2"><Button variant="outline-secondary" onClick={handleDirectoryClick}>Выбрать
                            папку</Button></Row>
                        <Row className="p-2"><Button variant="outline-secondary" onClick={handleClick}>Выбрать
                            файлы</Button></Row>
                        <Row className="p-2"><DropboxChooser setFileList={setFileList}/></Row>
                        <Row><LanguagesModal languages={languages}/></Row>
                        <Row><HelpModal/></Row>
                    </Col>
                    <Col className="col-7 mt-3">
                        <FileDrop className="h-100" targetClassName="h-100"
                                  onDrop={handleFileDrop}>
                            {fileList.length ? <FileList fileList={fileList}/> : <DropArea/>}
                        </FileDrop>
                    </Col>
                    <Col>
                        <Form className="mt-3">
                            <input
                                style={{display: 'none'}}
                                ref={inputDirectoryRef}
                                type="file"
                                onChange={handleFileChange}
                                webkitdirectory=""
                                directory=""
                                multiple
                                key="input_directory_file"
                            />
                            <input
                                style={{display: 'none'}}
                                ref={inputRef}
                                type="file"
                                onChange={handleFileChange}
                                multiple
                                key="input_file"
                            />
                            <FormGroup>
                                <FormLabel>Проект <span className="text-danger">*</span></FormLabel>
                                <Form.Select id="project" placeholder="Проект" value={project}
                                             onChange={(e) => updateProjectsState(e.currentTarget.value)}>
                                    {projects.map((p) =>
                                            <option value={p.value}>{p.label} ({p.value})</option>
                                        )}
                                </Form.Select>
                            </FormGroup>
                            <FormGroup className="mt-4">
                                <FormLabel>Имя ролика <span className="text-danger">*</span></FormLabel>
                                <Form.Control id="name" type="text"
                                              onChange={(e) => setVideoFileName(e.currentTarget.value)}
                                              value={videoFileName}></Form.Control>
                            </FormGroup>
                            <FormGroup className="mt-4">
                                <FormLabel>Вариация</FormLabel>
                                <Form.Control id="variation" type="text"
                                              onChange={(e) => setVariation(e.currentTarget.value)}
                                              value={variation}></Form.Control>
                                <FormText>Можно несколько через запятую</FormText>
                            </FormGroup>
                            <FormGroup className="mt-4">
                                <FormLabel>Разрешение <span className="text-danger">*</span></FormLabel>
                                <CreatableSelect options={defaultResolutions} onChange={handleResolutionChange} ref={selectResolutionInputRef}
                                                 isMulti={true} placeholder=""></CreatableSelect>
                            </FormGroup>
                            <FormGroup className="mt-4">
                                {
                                    (project && fileList.length > 0 && videoFileName && resolutions.length > 0) ?
                                        <Button variant="primary" onClick={validateFilenames}>Проверить</Button>
                                        :
                                        <OverlayTrigger
                                            overlay={<Tooltip id="tooltip-disabled">Заполнены не все поля</Tooltip>}>
                                          <span className="d-inline-block">
                                            <Button disabled style={{pointerEvents: 'none'}}
                                                    variant="primary">Проверить</Button>
                                          </span>
                                        </OverlayTrigger>
                                }
                                <Button variant="outline-danger" className="ms-2"><FontAwesomeIcon icon="trash"
                                                                                                   onClick={clearForm}/></Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default FilenameValidator;

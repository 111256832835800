import {useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";

const LanguagesModal = ({languages}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Button variant="link" onClick={handleShow}>
                Список языков
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Доступные языки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Язык</th>
                                <th>Код</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            languages.map((lang) =>
                            <tr key={lang[0]}>
                                <td>{lang[1]}</td>
                                <td>{lang[0]}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )};

export default LanguagesModal;
import {ListGroup, OverlayTrigger, Popover, PopoverBody} from "react-bootstrap";

const FileList = ({fileList}) => {
    return (
        <ListGroup>
            {
                fileList.map((fileObj) =>
                        <ListGroup.Item key={fileObj.webkitRelativePath || fileObj.name}
                            className={(fileObj.isValid === undefined) ? "" : fileObj.isValid ? "bg-success-subtle" : "bg-danger-subtle"}>
                            <div className="d-flex">
                                <div className="p-2 flex-grow-1"><span className="text-secondary">{fileObj.directory}</span><span>{fileObj.name}</span>
                                </div>
                                {fileObj.validationErrors && !fileObj.isValid ?
                                    <div className="p-2">
                                        <OverlayTrigger overlay={
                                            <Popover id="poptooltipover-disabled">
                                                <PopoverBody><ol>{
                                                    fileObj.validationErrors.map((e) => <li>{e}</li>)
                                                }</ol></PopoverBody></Popover>
                                        } placement="right">
                                            <span className="d-inline-block">
                                                <span className="badge rounded-pill text-bg-danger" role="button">?</span>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                    : ""
                                }
                            </div>
                        </ListGroup.Item>
                )
            }
        </ListGroup>
    )
};

export default FileList;

import {Button} from "react-bootstrap";

function DropboxChooser({setFileList}) {

    const selectFiles = files => {
        setFileList(files.map((f) => {
            return {
                'isValid': undefined,
                'name': f.name,
                'directory': ''
            }
        }))
    };
    const openChooser = event => {
        window.Dropbox.choose({
            success: selectFiles,
            cancel: function() {},
            linkType: "preview",
            multiselect: true,
        })
    }

    return (
        <Button variant="outline-secondary" onClick={openChooser}>Выбрать в Dropbox</Button>
    )

}
export default DropboxChooser;